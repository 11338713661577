.straightprod {
  width: 100vw;
  /* min-height: 1100px; */
  /* background-image: url("../assets/_images/bag1.png"); */
  /* background-image: url("../assets/_images/field-100.jpg"); */
  /* background-size: 25%; */
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: cover;
  /* background-color: rgb(224, 224, 224); */
  display: flex;
  justify-content: space-between;
  position: relative;
}
.straightsbag {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -30%);
  /* z-index: -10; */
  width: 500px;
}
.straightscontact {
  background-color: var(--blue);
  padding: 1.5rem 2.5rem;
  font-family: "Noto Sans", sans-serif;
  position: absolute;
  width: 430px;
  bottom: 150px;
  right: 0;
  color: white;
  text-align: left;
  font-size: 1.2rem;
  font-weight: 800;
  border: none;
}

.straightswesolve {
  display: flex;
  position: absolute;
  top: 120px;
  right: 100px;
}
.straightswesolve h1 {
  font-family: "Noto Sans", sans-serif;
  font-size: 3rem;
  color: rgb(230, 230, 230);
}
.straightslogo {
  position: relative;
  width: 150px;
  height: 100px;
  /* left: 45px; */
}
.straightsprod-wrapper {
  display: flex;
  width: 80%;
  margin: 0 auto;
  justify-content: space-between;
}
.straightsleft {
  display: flex;
  flex-basis: 40%;
  /* align-items: center; */
  min-height: 87vh;
  flex-direction: column;
  /* justify-content: center; */
  padding-top: 8rem;
}
.straightsleft h1 {
  font-family: "Noto Sans", sans-serif;
  color: var(--blue);
  color: gray;
  margin-bottom: 0;
  padding-bottom: 1rem;
  border-bottom: 1px dashed gray;
}
.straightsleft h2 {
  font-family: "Bebas Neue", sans-serif;
  color: rgb(53, 53, 53);
  margin: 1rem 0;
  font-size: 2.5rem;
}
.straightsleft h3 {
  margin: 0;
  font-family: "Bebas Neue", sans-serif;
  color: var(--blue);
  font-size: 2rem;
}
.straightsdescription {
  padding: 1rem;
  /* background-color: rgba(0, 0, 0, 0.096); */
  margin-top: 4rem;
  margin-left: 0rem;
  width: 70%;
  font-family: "Montserrat", sans-serif;
  border-radius: 0.75rem;
}
.straightsdescription ul {
  padding: 0;
  margin: 0;
  margin-left: 1rem;
}
.straightsdescription ul li {
  margin-bottom: 1rem;
}
.straightsdescription h4 {
  font-size: 1.5rem;
  color: var(--blue);
}
.straightsright {
  display: flex;
  flex-basis: 50%;
  min-height: 87vh;
  /* background-color: var(--blue); */
}
