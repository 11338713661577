.prefooter {
  background-color: rgb(49, 49, 49);
  width: 100%;
  min-height: 400px;
}
.prefooter-wrapper {
  width: 80%;
  margin: 0 auto;
}
.prefootertag {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 7rem;
  border-bottom: 1px white solid;
  padding-bottom: 3rem;
}
.prefooterslogan {
  margin: 0;
  color: white;
  font-family: "Noto Sans", sans-serif;
  font-size: 3rem;
}
.prefooterlogo {
  width: 180px;
}
footer {
  background-color: black;
  height: 50px;
  width: 100%;
  display: flex;
}
.footer-wrapper {
  width: 80%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  color: white;
  font-family: "Noto Sans", sans-serif;
}
.footer-wrapper p {
  display: flex;
  justify-content: space-between;
  width: 40%;
  font-size: 0.9rem;
}
.footer-wrapper p a {
  color: white;
  text-decoration: none;
}
.socialfooter {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 3rem;
}
.socialfooter p {
  font-family: "Noto Sans", sans-serif;
  font-size: 1.5rem;
  color: white;
}
.socialfooter img {
  width: 30px;
  height: 30px;
  margin-left: 2rem;
}
.officesfooter {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 7rem;
  padding-top: 3rem;
  font-size: 0.9rem;
}
.officefoot {
  display: flex;
  flex-direction: column;
  color: white;
  font-family: "Noto Sans", sans-serif;
}
.officefoot ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

@media only screen and (max-width: 1600px) {
  .footer-wrapper p {
    width: 50%;
  }
}
@media only screen and (max-width: 1245px) {
  .footer-wrapper p {
    width: 60%;
    font-size: 0.75rem;
  }
  .officefoot ul {
    font-size: 0.75rem;
  }
}
@media only screen and (max-width: 1000px) {
  .prefooterslogan {
    font-size: 2.5rem;
  }
  .prefooterlogo {
    width: 140px;
  }
}
@media only screen and (max-width: 850px) {
  .officefoot {
    flex-basis: 30%;
    margin-bottom: 2rem;
  }
  .officesfooter {
    flex-wrap: wrap;
  }
}
@media only screen and (max-width: 750px) {
  .prefooterslogan {
    font-size: 2.2rem;
  }
  .prefooterlogo {
    width: 120px;
  }
  .footer-wrapper p {
    width: 80%;
  }
}
@media only screen and (max-width: 650px) {
  .prefooterslogan {
    font-size: 2rem;
  }
  .prefooterlogo {
    width: 110px;
  }
}
@media only screen and (max-width: 550px) {
  footer {
    height: auto;
  }
  .footer-wrapper p {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .footnmob {
    display: none;
  }
  .prefootertag {
    flex-direction: column;
    align-items: flex-start;
    flex-flow: column-reverse;
  }
  .prefooterlogo {
    width: 130px;
    margin-bottom: 4rem;
  }
  .prefooterslogan {
    font-size: 1.7rem;
  }
  .officesfooter {
    padding-bottom: 3rem;
  }
  .socialfooter {
    display: flex;
    flex-wrap: wrap;
  }
  .socialfooter p {
    min-width: 100%;
  }
  .socialfooter .socfooticon1 {
    margin-left: 0;
  }
}
@media only screen and (max-width: 450px) {
  .officefoot {
    flex-basis: 90%;
    margin-bottom: 2rem;
  }
  .inhibitorcard-bottom {
    flex-direction: column;
  }
  .officefoot ul {
    font-size: 0.95rem;
  }
  .officefoot {
    flex-basis: 45%;
    margin-bottom: 2rem;
  }
}
