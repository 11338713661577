.nitrifixmain {
  min-height: 1050px;
  width: 100%;
}
.nitrifixmainwrapper {
  width: 80%;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  height: 1050px;
}
.nfxmaintag {
  display: flex;
  flex-basis: 30%;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.nfxlogo {
  height: 65px;
  margin-bottom: 3rem;
}
.nfxmaintag p {
  font-family: "Montserrat", sans-serif;
  font-size: 1.4rem;
  line-height: 2.5rem;
}
.icontag {
  display: flex;
  flex-direction: row;
  /* margin-top: 4rem; */
  align-items: center;
}
.icontagfirst {
  margin-top: 4rem;
}
.iconmain {
  height: 60px;
  margin-right: 2rem;
}
.icontag p {
  font-family: "Noto Sans", sans-serif;
  font-weight: 600;
  font-size: 1.2rem;
  line-height: 1.7rem;
}
.nfxmainimg {
  height: 1000px;
  display: flex;
  flex-basis: 70%;
  justify-content: flex-end;
  align-items: center;
}
.nfximg {
  height: 630px;
}
.nfxsection2 {
  min-height: 400px;
  background-color: rgb(241, 241, 241);
  width: 100%;
}
.nfxsection2-wrapper {
  width: 80%;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 440px;
  justify-content: space-between;
}
.nfx2msg {
  display: flex;
  flex-direction: row;
  /* flex-basis: 40%; */
  width: 670px;
  justify-content: space-between;
  height: 270px;
  /* height: auto; */
}
.nfx2msg:last-child {
  margin-left: 3rem;
}
.nfx2prot {
  display: flex;
  flex-direction: column;
  flex-basis: 65%;
  height: 200px;
}
.nfx2prot h2 {
  font-family: "Noto Sans", sans-serif;
  margin-top: 1rem;
}
.nfx2prot p {
  font-family: "Montserrat", sans-serif;
  line-height: 1.75rem;
  font-size: 1.1rem;
}
.nfxsection3 {
  min-height: 1150px;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  background-color: rgb(231, 231, 231);
}
.nfxsection3-wrapper {
  width: 80%;
  margin: 0 auto;
  height: 850px;
}
.nfxsection3 img {
  position: absolute;
  height: 850px;
  top: 100px;
  right: -40px;
}
.nfx3item1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 4rem;
}
.nfx3item1text {
  /* margin-left: 2rem; */
  font-family: "Montserrat", sans-serif;
  font-size: 1.2rem;
}
.nfx3item1text p {
  line-height: 1.9rem;
  font-size: 1.1rem;
  width: 700px;
}
.nfx3item1text h2 {
  font-size: 1.6rem;
  font-family: "Noto Sans", sans-serif;
}
.nfx3item1line {
  border-top: 1px solid #30353b;
  padding-top: 10px;
  padding-bottom: 10px;
  margin: 40px auto 0;
  width: 40%;
  position: relative;
}
.nfx3item1line:before,
.nfx3item1line:after {
  content: "";
  width: 15px;
  height: 15px;
  background: #30353b;
  border-radius: 35px;
  position: absolute;
  top: -8px;
  right: 0;
}
.inhibvollogo {
  height: 60px;
}
.inhiblegend .inhibicontext {
  font-family: "Noto Sans", sans-serif;
  /* font-weight: 600; */
  font-size: 0.95rem;
}
.inhibicon {
  height: 60px;
  margin-right: 1rem;
}
.inhibicongroup {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 1rem;
}
.inhibicons {
  /* min-height: 250px; */
  /* margin-top: 2rem; */
}
.inhibicons p {
  margin-top: 0;
}
.nfxitemshort {
  margin-right: 10%;
}
.nfxitemlong {
  margin-right: 23%;
}

@media only screen and (max-width: 1850px) {
  .nitrifixmain {
    min-height: 950px;
  }
  .nitrifixmainwrapper {
    height: 950px;
  }
  .nitrifixmain .icontagfirst {
    margin-top: 2rem;
  }
}
@media only screen and (max-width: 1750px) {
  .nfximg {
    /* width: 750px; */
    height: 560px;
  }
  .nfxmainimg {
    height: 920px;
  }
  .nfxinhibcard {
    width: 260px;
  }
}
@media only screen and (max-width: 1650px) {
  .nfxmaintag p {
    font-size: 1.2rem;
    line-height: 2.2rem;
  }
  .nfxmaintag .iconmsg {
    font-size: 1.1rem;
  }
  .nfximg {
    /* width: 750px; */
    margin-left: 2rem;
  }
  .nfxlogo {
    height: 55px;
  }
  .nfxsection2-wrapper {
    height: 460px;
  }
  .nfx2msg {
    height: 330px;
  }
}
@media only screen and (max-width: 1550px) {
  .nfximg {
    /* width: 750px; */
    height: 530px;
  }
  .nfxsection2-wrapper {
    height: 490px;
  }
  .nfx2msg {
    height: 350px;
  }
}
@media only screen and (max-width: 1450px) {
  .mfximg {
    height: 450px;
  }
  .nfxmaintag p {
    font-size: 1.1rem;
    line-height: 2rem;
  }
  .nfxmaintag .iconmsg {
    font-size: 1rem;
  }
  .nfxmaintag {
    flex-basis: 55%;
    min-width: 300px;
  }
  .nfxmainimg {
    flex-basis: 40%;
  }
  .nfx2prot {
    margin-left: 2.5rem;
  }
  .nfxsection2-wrapper {
    height: 510px;
  }

  .inhibvollogo {
    height: 50px;
  }
  .inhibintlogo {
    height: 55px;
  }
  .inhibnurlogo {
    height: 55px;
  }
  .inhibcardtitle {
    min-height: 50px;
    margin-top: 1rem;
  }
  .nfxinhibcard {
    height: 440px;
    padding: 3rem 2.5rem;
  }
}

@media only screen and (max-width: 1377px) {
  .nfx2msg {
    height: 380px;
  }
}
@media only screen and (max-width: 1350px) {
  .nfxinhibcard {
    width: 230px;
  }
  .nfxinhibcard p {
    min-height: 280px;
  }
}

@media only screen and (max-width: 1320px) {
  .nfxsection2-wrapper {
    height: 550px;
  }
  .nfx2msg {
    height: 410px;
  }
}
@media only screen and (max-width: 1250px) {
  .nfx2msg {
    height: 440px;
  }
  .nfxsection2-wrapper {
    height: 580px;
  }
}
@media only screen and (max-width: 1220px) {
  .nfx2msg {
    height: 480px;
  }
  .nfxsection2-wrapper {
    height: 610px;
  }
}
@media only screen and (max-width: 1200px) {
  .nfx2msg {
    height: 500px;
  }
  .nfxsection2-wrapper {
    height: 640px;
  }
  .nfx3item1line {
    display: none;
  }
  .nfx3item1 {
    margin-right: 25%;
  }
  .nfx3item1text p {
    width: auto;
  }
  .nfx3item1:last-child {
    margin-bottom: 6rem;
  }
  .nfx3item1:first-child {
    margin-top: 6rem;
  }
  .nfxsection3 {
    min-height: auto;
  }
  .nfxsection3-wrapper {
    height: auto;
  }
  .nfxsection3 img {
    top: 40px;
  }
  .nfxinhibcard p {
    min-height: 80px;
  }
  .nfxinhibcard {
    height: auto;
    width: 210px;
  }
}
@media only screen and (max-width: 1150px) {
  .nfxsection2-wrapper {
    flex-direction: column;
  }
  .nfx2msg {
    width: 100%;
    margin-top: 6rem;
    min-width: 0;
    height: auto;
  }
  .nfx2prot {
    flex-basis: 75%;
    margin-left: 4rem;
    min-width: 0;
  }
  .nfx2msg:last-child {
    margin-left: 0;
    margin-top: 4rem;
  }
  .nfxsection2 {
    min-height: 790px;
  }
}
@media only screen and (max-width: 1000px) {
  .nfx3item1 {
    margin-right: 0;
    width: 80%;
    margin: 0 auto;
    margin-bottom: 4rem;
  }
  .nfxsection3-wrapper {
    background-color: rgba(45, 45, 45, 0.6);
    z-index: 1;
    width: 100%;
    height: auto;
    color: white;
  }
}
@media only screen and (max-width: 950px) {
  .nfxinhibcard {
    width: 100%;
  }
}
@media only screen and (max-width: 850px) {
  .nitrifixmainwrapper {
    flex-direction: column;
    height: 1200px;
  }
  .nfxmaintag {
    /* min-height: 0; */
    flex-basis: 36%;
    padding-top: 7rem;
  }
  .nfxmainimg {
    width: 110%;
    margin-left: 0;
  }
  .nfxlogo {
    height: 55px;
  }
  .nfxmaintag .icontagfirst {
    margin-top: 2rem;
  }
  .nfxsection2 {
    min-height: 790px;
  }
  .nfx2msg:last-child {
    margin-top: 3rem;
  }
  .nfx2msg {
    min-height: 260px;
  }
}
@media only screen and (max-width: 750px) {
  .nfxsection2 {
    min-height: 860px;
  }
  .nfx2msg {
    min-height: 280px;
  }
}
@media only screen and (max-width: 690px) {
  .nfxsection2 {
    min-height: 920px;
  }
  .nfx2msg {
    min-height: 310px;
  }
  .nfx3item1text h2 {
    font-size: 1.4rem;
  }
  .nfx3item1text p {
    font-size: 1rem;
  }
}
@media only screen and (max-width: 650px) {
  .nitrifixmainwrapper {
    height: 1250px;
  }
  .nfxlogo {
    height: 50px;
  }
  .nfx2prot p {
    font-size: 1rem;
  }
  .nfx2prot h2 {
    font-size: 1.4rem;
  }
}
@media only screen and (max-width: 575px) {
  .nfxsection2 {
    min-height: 970px;
  }
  .nfx2msg {
    min-height: 310px;
  }
}
@media only screen and (max-width: 550px) {
  .nfx2msg {
    flex-direction: column;
    min-height: auto;
  }
  .nfx2msg:last-child {
    margin-top: 4rem;
  }
  .nfx2prot {
    flex-basis: 100%;
    margin-left: 0;
  }
  .nfxsection2 {
    min-height: 950px;
  }
}
@media only screen and (max-width: 450px) {
  .nitrifixmainwrapper {
    height: 1350px;
  }
  .nfxlogo {
    height: 45px;
  }
  .nfxsection2 {
    min-height: 1120px;
  }
  .nfxinhibcard {
    border: none;
    padding: 4rem 0;
    margin: 0;
    border-bottom: 1px dashed rgb(214, 214, 214);
    border-radius: 0;
    height: auto;
    background-color: white;
  }
  .inhibvollogo {
    height: 55px;
  }
  .lastinhibcard {
    border-bottom: none;
  }
}
