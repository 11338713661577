.integramain {
  min-height: 1100px;
  width: 100%;
}
.integramainwrapper {
  width: 80%;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  height: 1100px;
}
.intmaintag {
  display: flex;
  flex-basis: 30%;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.intlogo {
  height: 75px;
  margin-bottom: 3rem;
}
.intmaintag p {
  font-family: "Montserrat", sans-serif;
  font-size: 1.4rem;
  line-height: 2.5rem;
}
.icontag {
  display: flex;
  flex-direction: row;
  /* margin-top: 4rem; */
  align-items: center;
}
.icontagfirst {
  margin-top: 4rem;
}
.iconmain {
  height: 60px;
  margin-right: 2rem;
}
.icontag p {
  font-family: "Noto Sans", sans-serif;
  font-weight: 600;
  font-size: 1.2rem;
  line-height: 1.7rem;
}
.intmainimg {
  height: 1000px;
  display: flex;
  flex-basis: 70%;
  justify-content: flex-end;
  align-items: center;
}
.intimg {
  height: 700px;
}
.intsection2 {
  background-color: rgb(241, 241, 241);
  width: 100%;
  padding-top: 9rem;
}
.intsection22 {
  padding-bottom: 9rem;
  padding-top: 7rem;
}
.intsection2-wrapper {
  width: 80%;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
}
.int2msg {
  display: flex;
  flex-direction: row;
  /* flex-basis: 40%; */
  width: 670px;
  justify-content: space-between;
  /* height: 270px; */
}
.int2prot {
  display: flex;
  flex-direction: column;
  flex-basis: 65%;
  /* height: 200px; */
}
.int2prot h2 {
  font-family: "Noto Sans", sans-serif;
  margin-top: 1rem;
}
.int2prot p {
  font-family: "Montserrat", sans-serif;
  line-height: 1.75rem;
  font-size: 1.1rem;
}
.intsection3 {
  min-height: 1250px;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  background-color: rgb(231, 231, 231);
}
.intsection3-wrapper {
  width: 80%;
  margin: 0 auto;
  height: 950px;
}
.intinhibcard {
  height: 430px;
  width: 260px;
}
.intinhibcard p {
  min-height: 280px;
}

@media only screen and (max-width: 1890px) {
  .intlogo {
    margin-bottom: 2rem;
  }
  .intmaintag .icontagfirst {
    margin-top: 1rem;
  }
  .integramainwrapper {
    height: 950px;
  }
  .integramain {
    min-height: 1000px;
  }
}
@media only screen and (max-width: 1750px) {
  .intimg {
    /* width: 750px; */
    height: 630px;
  }
  .intmainimg {
    height: 890px;
  }
  .int2msg:last-child {
    margin-left: 2.5rem;
  }
  .int2prot {
    margin-left: 2.5rem;
  }
}
@media only screen and (max-width: 1650px) {
  .intmaintag p {
    font-size: 1.2rem;
    line-height: 2.2rem;
  }
  .intmaintag .iconmsg {
    font-size: 1.1rem;
    line-height: 1.7rem;
  }
}
@media only screen and (max-width: 1550px) {
  .intimg {
    height: 610px;
  }
}
@media only screen and (max-width: 1450px) {
  .intimg {
    height: 580px;
  }
  .intmaintag p {
    font-size: 1.1rem;
    line-height: 2rem;
  }
  .intmaintag .iconmsg {
    font-size: 1rem;
  }
  .intinhibcard {
    height: 460px;
    width: 230px;
  }
  .intinhibcard p {
    min-height: 320px;
  }
}
@media only screen and (max-width: 1200px) {
  .intinhibcard {
    height: auto;
    width: auto;
  }
  .intinhibcard p {
    min-height: auto;
  }
}
@media only screen and (max-width: 1150px) {
  .intsection2-wrapper {
    flex-direction: column;
  }
  .intsection22 {
    padding-top: 0;
    padding-bottom: 4rem;
  }
  .int2msg {
    width: 100%;
    margin-bottom: 5rem;
  }
  .int2msg:last-child {
    margin-left: 0;
    margin-bottom: 5rem;
  }
  .int2prot {
    flex-basis: 70%;
  }
}
@media only screen and (max-width: 850px) {
  .integramainwrapper {
    flex-direction: column;
    height: 1350px;
    padding-top: 3rem;
  }
  .intmainimg {
    flex-basis: auto;
    height: auto;
    margin-top: 2rem;
  }
  .intmaintag {
    /* min-height: 0; */
    flex-basis: 36%;
    padding-top: 7rem;
  }
  .intmainimg {
    width: 108%;
    margin-left: 0;
  }
  .intlogo {
    height: 70px;
  }
}
@media only screen and (max-width: 650px) {
  .int2prot h2 {
    font-size: 1.4rem;
  }
  .int2prot p {
    font-size: 1rem;
  }
  .intlogo {
    height: 65px;
  }
}
@media only screen and (max-width: 550px) {
  .int2msg {
    flex-direction: column;
  }
  .int2prot {
    margin-left: 0;
  }
}
@media only screen and (max-width: 450px) {
  .integramainwrapper {
    padding-top: 1rem;
  }
  .intmainimg {
    margin-top: 0rem;
  }
}
