.navbarg {
  /* display: none; */
  opacity: 0;
  height: 60px;
  width: 100%;
  background-color: white;
  position: fixed;
  padding-bottom: 0;
  /* display: block; */
  transition: all 2s ease-in-out;
  -moz-transition: all 2s ease-in-out;
  -webkit-transition: all 2s ease-in-out;
  z-index: 10;
  -webkit-box-shadow: 0px 6px 15px -13px rgba(161, 161, 161, 1);
  -moz-box-shadow: 0px 6px 15px -13px rgba(161, 161, 161, 1);
  box-shadow: 0px 6px 15px -13px rgba(161, 161, 161, 1);
}
.navbargdark {
  background-color: black !important;
}
.navcontainerg {
  width: 80%;
  margin: 0 auto;
  padding: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.navitemsg {
  display: flex;
  flex-direction: row;
  width: 600px;
  align-items: center;
}
.navg {
  display: flex;
  list-style: none;
  width: 100%;
  justify-content: space-between;
  margin: 0;
  margin-left: 80px;
  padding: 0;
  color: rgb(124, 124, 124);
}
.navgdark {
  color: white;
}
.navg li {
  font-family: "Teko", sans-serif;
  font-size: 1.5rem;
  position: relative;
  padding: 0.7rem 0;
  border-bottom: 2px solid transparent;
  border-top: 2px solid transparent;
}
.navg #dropdowng {
  display: none;
  position: absolute;
  z-index: 30;
  overflow-y: visible;
  top: 60px;
  list-style: none;
  padding-left: 0;
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  left: -320px;
  width: 83vw;
  background-color: white;
  border: 1px rgb(189, 189, 189) solid;
  padding: 1rem 5rem;
  padding-top: 3rem;
}
.navg li:hover {
  cursor: pointer;
  border-bottom: 2px solid var(--blue);
}
.navg li:hover #dropdowng {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  animation: showdropdowng 300ms forwards;
}
@keyframes showdropdowng {
  0% {
    display: none;
    opacity: 0;
  }
  100% {
    display: flex;
    opacity: 1;
  }
}
.ddghide {
  display: none !important;
}
.ddgexpand {
  display: flex;
}
#dropdowng li {
  padding: 0;
  border-bottom: none;
}
#dropdowng li:hover {
  /* border: none; */
}
#dropdowng li a:hover {
  cursor: pointer;
  /* border-bottom: none; */
  /* background-color: rgb(245, 245, 245); */
}
/* #dropdowng li:first-child:hover {
  border-top-right-radius: 0.5rem;
  border-top-left-radius: 0.5rem;
  background-color: white;
}
#dropdowng li:last-child:hover {
  border-bottom-right-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
} */
.navg ul li {
  display: flex;
  flex-basis: 1;
  flex-direction: column;
  margin-bottom: 1rem;
  font-size: 1.3rem;
}
#dropdowng li a {
  border-bottom: gray dashed 1px;
  margin-bottom: 1rem;
}
#dropdowng ul li {
  margin-bottom: 0;
}
#dropdowng ul {
  padding-left: 0;
}
#dropdowng ul ul {
  list-style: disc;
  padding-left: 0rem;
  margin-bottom: 1rem;
}
#dropdowng ul ul li {
  font-family: "Montserrat", sans-serif;
  font-size: 0.85rem;
  line-height: 1.3rem;
}
#dropdowng ul ul li:hover {
  cursor: pointer;
  color: var(--blue);
  /* font-weight: 600; */
}
#dropdowng ul ul li a {
  border-bottom: none;
  margin-bottom: 0;
  padding-bottom: 0;
}
.navg ul li a {
  text-decoration: none;
  background-color: white;
  /* background-color: rgb(66, 66, 66); */
  padding-bottom: 1rem;
  color: rgb(124, 124, 124);
  /* color: white; */
  width: 100%;
  /* width: 400px; */
  /* display: block; */
  /* padding: 0.5rem 2rem; */
  border: none;
}
.navg ul li a:hover {
  background-color: rgb(245, 245, 245);
  cursor: pointer;
  color: var(--blue);
}
/* .navg ul li:first-child a {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.navg ul li:last-child a {
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
} */
.wesolvenavlist {
  display: flex;
  flex-direction: row;
}
.wesolvenavlist div:nth-child(1) {
  padding-right: 3rem;
}
.protnpknavlist {
  display: flex;
  flex-direction: column;
}
.navg ul .navgprodgroup {
  color: var(--blue);
  font-size: 1.2rem;
  font-family: "Noto Sans", sans-serif;
  font-weight: 600;
}
.fluidorgnavlist {
  display: flex;
  flex-direction: column;
}
.languageselect {
  border: none;
  height: 60px;
  /* margin-right: 5%; */
}
.languageselectdark {
  background-color: black;
  color: white;
}
#dropdowng ul ul li {
  display: flex;
  flex-direction: row;
}
@media only screen and (max-width: 1450px) {
  #dropdowng ul ul li {
    font-size: 0.75rem;
  }
}
@media only screen and (max-width: 1110px) {
  .navg #dropdowng {
    width: 90vw;
    padding: 1rem 3rem;
    padding-top: 3rem;
    left: -340px;
  }
}
@media only screen and (max-width: 1000px) {
  .navcontainerg {
    padding-top: 1rem;
  }
}
