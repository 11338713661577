.disclaimerwrapper {
  margin: 0;
  padding: 0;
  display: flex;
}
.disclaimercontent {
  width: 80%;
  margin: 0 auto;
  min-height: 900px;
  font-family: "Noto Sans", sans-serif;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
