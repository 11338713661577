:root {
  --blue: #4441a2;
  --lblue: #6765b3;
}
html,
body {
  position: relative;
}
.wrapper {
  position: relative;
  overflow-x: hidden;
  width: 100%;
}
h1 sup {
  font-size: 1.3rem;
}
p sup {
  font-size: 1rem;
}
.mainhome {
  height: 95vh;
  width: 100%;
  /* background-image: url("../assets/_images/home-background.png"); */
  background-image: url("../assets/_images/growingplant.jpg");
  background-size: cover;
  background-position: center center;
  min-height: 800px;
  position: relative;
  /* clip-path: circle(50% at 50% 50%); */
}
.bubble {
  position: absolute;
  top: 75%;
  right: 20%;
}
.navbar2 {
  /* display: none; */
  opacity: 0;
  height: 60px;
  width: 100%;
  background-color: white;
  position: fixed;
  padding-bottom: 0;
  /* display: block; */
  transition: all 2s ease-in-out;
  -moz-transition: all 2s ease-in-out;
  -webkit-transition: all 2s ease-in-out;
  z-index: 10;
  -webkit-box-shadow: 0px 6px 15px -13px rgba(161, 161, 161, 1);
  -moz-box-shadow: 0px 6px 15px -13px rgba(161, 161, 161, 1);
  box-shadow: 0px 6px 15px -13px rgba(161, 161, 161, 1);
}
/* .displaynone {
    display: none;
    opacity: 0;
  } */
/* .displayblock {
    display: block;
    opacity: 1;
  } */

.displayblock {
  animation: shownav 500ms forwards;
}
@keyframes hidenav {
  /* 0% {
    opacity: 1;
  } */
  100% {
    opacity: 0;
    /* display: none; */
  }
}
.displaynone {
  animation: hidenav 1000ms forwards;
}
@keyframes shownav {
  0% {
    display: none;
    opacity: 0;
  }
  100% {
    display: block;
    opacity: 1;
  }
}
.navcontainer2 {
  width: 80%;
  margin: 0 auto;
  padding: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.navitems2 {
  display: flex;
  flex-direction: row;
  width: 600px;
  align-items: center;
}
.nav2 {
  display: flex;
  list-style: none;
  width: 100%;
  justify-content: space-between;
  margin: 0;
  margin-left: 80px;
  padding: 0;
}

.nav2 li {
  font-family: "Teko", sans-serif;
  font-size: 1.5rem;
  color: rgb(124, 124, 124);
  position: relative;
  /* height: 70px; */
  padding: 0.7rem 0;
  border-bottom: 2px solid transparent;
  border-top: 2px solid transparent;
}
.nav2 li a {
  color: rgb(124, 124, 124);
  text-decoration: none;
}
.nav2 #dropdown {
  display: none;
  position: absolute;
  z-index: 30;
  overflow-y: visible;
  /* background-color: white; */
  top: 58px;
  list-style: none;
  padding-left: 0;
  /* width: 500px; */
  border-radius: 0.5rem;
  /* border: 1px solid rgb(211, 211, 211); */
  left: -320px;
  width: 83vw;
  background-color: white;
  border: 1px rgb(189, 189, 189) solid;
  /* background-color: rgba(255, 255, 255, 0.616); */

  /* border: 1px solid rgb(211, 211, 211); */
  /* -webkit-backdrop-filter: blur(50px);
  backdrop-filter: blur(50px); */
  padding: 1rem 5rem;
  padding-top: 3rem;
}

.nav2 li:hover {
  cursor: pointer;
  border-bottom: 2px solid var(--blue);
}
.nav2 li:hover #dropdown {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  animation: showdropdowng 300ms forwards;
}
@keyframes showdropdowng {
  0% {
    display: none;
    opacity: 0;
  }
  100% {
    display: flex;
    opacity: 1;
  }
}
#dropdown li {
  padding: 0;
  border-bottom: none;
}
#dropdown li:first-child {
  /* padding-top: 1rem; */
}
#dropdown li:last-child {
  /* padding-bottom: 1rem; */
}
#dropdown li a:hover {
  cursor: pointer;
  /* background-color: rgb(245, 245, 245); */
}
/* #dropdown li:first-child:hover {
  border-top-right-radius: 0.5rem;
  border-top-left-radius: 0.5rem;
}
#dropdown li:last-child:hover {
  border-bottom-right-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
} */
.nav2 ul li {
  display: flex;
  flex-basis: 1;
  flex-direction: column;
  margin-bottom: 1rem;
  font-size: 1.3rem;
}
/* #dropdown {
  transition: 0.5s;
  border: none; 
} */
.navg li a {
  text-decoration: none;
  color: rgb(124, 124, 124);
}
#dropdown li a {
  border-bottom: gray dashed 1px;
  margin-bottom: 1rem;
}
#dropdown ul li {
  margin-bottom: 0;
}
#dropdown ul {
  padding-left: 0;
}
#dropdown ul ul {
  list-style: disc;
  padding-left: 0rem;
  margin-bottom: 1rem;
}
#dropdown ul ul li {
  font-family: "Montserrat", sans-serif;
  font-size: 0.85rem;
  line-height: 1.3rem;
}
#dropdown ul ul li a {
  border-bottom: none;
  margin-bottom: 0;
  padding-bottom: 0;
}
#dropdown ul ul li:hover {
  cursor: pointer;
  color: var(--blue);
}
.nav2 ul li a {
  text-decoration: none;
  background-color: white;
  /* background-color: rgb(66, 66, 66); */
  padding-bottom: 1rem;
  color: rgb(124, 124, 124);
  /* color: white; */
  width: 100%;
  /* display: block;
  padding: 0.5rem 2rem; */
  border: none;
}
.nav2 ul li a:hover {
  background-color: rgb(245, 245, 245);
  cursor: pointer;
  color: var(--blue);
}
.nav2 ul .navgprodgroup {
  color: var(--blue);
  font-size: 1.2rem;
  font-family: "Noto Sans", sans-serif;
  font-weight: 600;
}
/* .nav2 ul li:first-child a {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.nav2 ul li:last-child a {
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
} */

.nav {
  display: flex;
  list-style: none;
  width: 30%;
  min-width: 550px;
  justify-content: flex-start;
  margin-left: 80px;
  z-index: 10;
}

.nav li {
  color: white;
  text-decoration: none;
  font-family: "Teko", sans-serif;
  font-size: 1.7rem;
  position: relative;
}
.navtitle {
  margin-right: 5rem;
}
.nav li a {
  color: white;
  text-decoration: none;
}

.nav #dropdown3 {
  display: none;
  position: absolute;
  z-index: 30;
  overflow-y: visible;
  top: 45px;
  list-style: none;
  padding-left: 0;
  border-radius: 0.5rem;
  left: -350px;
  width: 83vw;
  background-color: white;
  border: 1px rgb(189, 189, 189) solid;
  padding: 1rem 5rem;
  padding-top: 3rem;
}

.nav li:hover {
  cursor: pointer;
  border-bottom: 2px solid white;
}

.nav li:hover #dropdown3 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  animation: showdropdowng 300ms forwards;
}
#dropdown3 li {
  padding: 0;
  border-bottom: none;
  color: rgb(124, 124, 124);
}
#dropdown3 li a:hover {
  cursor: pointer;
  /* background-color: rgb(245, 245, 245); */
}
.nav ul li {
  display: flex;
  flex-basis: 1;
  flex-direction: column;
  margin-bottom: 1rem;
  font-size: 1.3rem;
}
#dropdown3 li a {
  border-bottom: gray dashed 1px;
  margin-bottom: 1rem;
}
#dropdown3 ul li {
  margin-bottom: 0;
}
#dropdown3 ul {
  padding-left: 0;
}
#dropdown3 ul ul {
  list-style: disc;
  padding-left: 0rem;
  margin-bottom: 1rem;
}
#dropdown3 ul ul li {
  font-family: "Montserrat", sans-serif;
  font-size: 0.85rem;
  line-height: 1.3rem;
}
#dropdown3 ul ul li:hover {
  cursor: pointer;
  color: var(--blue);
}
#dropdown3 ul ul li a {
  border-bottom: none;
  margin-bottom: 0;
  padding-bottom: 0;
}
.nav ul li {
  display: flex;
  flex-basis: 1;
  flex-direction: column;
  margin-bottom: 1rem;
  font-size: 1.3rem;
}
.nav ul li a {
  text-decoration: none;
  background-color: white;
  padding-bottom: 1rem;
  color: rgb(124, 124, 124);
  width: 100%;
  border: none;
}
.nav ul .navgprodgroup {
  color: var(--blue);
  font-size: 1.2rem;
  font-family: "Noto Sans", sans-serif;
  font-weight: 600;
  text-decoration: none;
}

.homepage {
  width: 80%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  height: 65vh;
  min-height: 500px;
  align-items: center;
}
.taghome {
  color: white;
  width: 100%;
  height: 70%;
  /* height: 100%; */
  font-family: "Noto Sans", sans-serif;
  font-size: 2.2rem;
  font-weight: 700;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* padding-top: 70px; */
}
.taghome h1 {
  font-size: 4rem;
}
.taghome h5 {
  font-weight: 300;
  width: 40%;
  min-width: 600px;
  padding-bottom: 1.5rem;
  font-family: "Montserrat", sans-serif;
  /* font-size: 2rem; */
  /* line-height: 3rem; */
  font-size: 1.5rem;
  line-height: 2.5rem;
  margin-top: 0;
}
.homebuttons {
  display: flex;
  flex-direction: row;
}
.mainbutton {
  color: white;
  background-color: #4441a2;
  border-radius: 0.5rem;
  padding: 1.3rem 3.9rem;
  font-size: 1.2rem;
  border: none;
  margin-right: 1.5rem;
  /* font-family: "Teko", sans-serif; */
}
.mainbutton:hover {
  cursor: pointer;
  background-color: #6765b3;
}
.socialicons {
  display: flex;
  width: 60px;
  height: 250px;
  flex-direction: column;
  /* width: 400px; */
  justify-content: space-between;
  /* align-self: flex-end; */
  position: absolute;
  top: 35%;
  right: 0%;
  background-color: rgb(0, 0, 0, 0.5);
  padding-top: 3rem;
  padding-left: 2.5rem;
  padding-bottom: 3rem;
  border-top-left-radius: 2rem;
  border-bottom-left-radius: 2rem;
}
.socialicons img {
  width: 30px;
}
.wgvalues {
  min-height: 90vh;
  width: 100%;
  background-color: rgb(80, 80, 80);
  /* background-color: rgb(53, 54, 23);
  background-color: white;
  background-color: rgb(153, 151, 151); */
  background-color: rgb(105, 99, 94);
  background-image: linear-gradient(
    rgb(88, 84, 80) 0%,
    rgb(105, 99, 94) 35%,
    rgb(88, 84, 80) 100%
  );

  /* background-image: url("../assets/_images/background-values.svg"); */
  background-size: 115%;
  background-repeat: no-repeat;
  background-position: bottom bottom;
}
.wgvalues-wrapper {
  width: 80%;
  margin: auto;
  display: flex;
  flex-direction: column;
  padding: 7rem 0;
}

.wgvalues-wrapper h1 {
  color: white;

  font-size: 3rem;
  font-family: "Noto Sans", sans-serif;
  padding-bottom: 3rem;
}
.inhibitors-wrapper h1 {
  font-size: 3rem;
  font-family: "Noto Sans", sans-serif;
}
.valuecards {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* padding-top: 2rem; */
  flex-wrap: wrap;
}
.valuecard {
  background-color: #black;
  display: flex;
  flex-direction: row;
  flex-basis: 44%;
  min-width: 520px;
  border-radius: 1rem;
  justify-content: space-between;
  margin-bottom: 3rem;
}
.valuecard:nth-child(2n-1) {
  margin-right: 1rem;
}
.valueicon {
  display: flex;
  flex-basis: 5%;
  padding: 0;
  width: 120px;
}
.valuecard-text {
  font-family: "Bebas Neue", sans-serif;
  font-family: "Montserrat", sans-serif;
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  /* justify-content: space-between; */
  flex-basis: 60%;
  padding-right: 2rem;
  color: white;
}
.valuecard-text h2 {
  font-size: 2rem;
  padding-bottom: 0;
  margin: 1.65rem 0;
  font-family: "Noto Sans", sans-serif;

  /* color: rgb(106, 134, 99); */
}
.valuecard-text p {
  font-family: "Montserrat", sans-serif;
  /* font-family: "Noto Sans", sans-serif; */
  font-size: 1rem;
  line-height: 1.8rem;
  margin: 1rem 0;
  margin-bottom: 3rem;
}
.inhibitorcards-section {
  background-color: rgba(240, 240, 240, 0.9);
  height: 100vh;
  height: auto;
  width: 100%;
}
.inhibitors {
  width: 100%;
  height: auto;
  /* background-image: url("../assets/_images/field-100.jpg"); */
  background-size: cover;
  background-position: center 10px;
  /* padding-bottom: 10rem; */
}
.inhibitorcards-wrapper {
  width: 80%;
  margin: 0 auto;
  padding-top: 3rem;
  padding-bottom: 7rem;
  font-family: "Noto Sans", sans-serif;
  position: relative;
}
.inhibitorcards-wrapper h2 {
  color: #868383;
  font-size: 2rem;
}
.inhibitors-wrapper {
  width: 80%;
  margin: auto;
  display: flex;
  flex-direction: column;
  padding: 7rem 0 2rem 0;
}
.inhibitorcards-wrapper h1 {
  /* color: #77848c; */
  font-size: 3rem;
  margin: 6rem 0 3rem 0;
}
.inhibitorcards-wrapper h3 {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 1.2rem;
  width: 70%;
  line-height: 2.2rem;
  padding-top: 1.5rem;
}
.inhibitorcards {
  width: 80%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 3rem;
  flex-wrap: wrap;
}
.inhibitorcard {
  display: flex;
  flex-direction: column;
  flex-basis: 40%;
  min-width: 400px;
  padding: 1rem 3rem 6rem 0;
  font-family: "Montserrat", sans-serif;
  color: black;
  line-height: 1.4rem;
  margin-bottom: 2rem;
  /* font-weight: 300; */
}
/* .inhibitorcard:nth-child(2n-1) {
  border-right: gray 1px dashed;
} */
.inhibitorcard p {
  /* height: 110px; */
  margin-bottom: 2.5rem;
  line-height: 1.8rem;
  margin-top: 1.8rem;
}
.volitlogo {
  /* height: 40px; */
  width: 140px;
}
.nitrifixlogo {
  width: 280px;
}
.integralogo {
  width: 220px;
}
.nurturelogo {
  width: 210px;
}
.inhibitorcard-bottom {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  position: relative;
}
.inhibitorcard span {
  color: #4441a2;
  font-weight: bold;
}
.inhibiticons {
  display: flex;
  flex-direction: row;
  width: 70%;
}
.inhibiticon {
  width: 60px;
  margin-right: 2rem;
}
.inhibitorbutton {
  position: absolute;
  bottom: 0;
  right: 0;
  color: white;
  background-color: var(--blue);
  /* background-color: rgb(129, 127, 127); */
  /* color: #4441a2; */
  border-radius: 0.25rem;
  padding: 0.5rem 2rem;
  font-size: 1.1rem;
  border: none;
  width: 160px;
  height: 45px;
}
.inhibitorbutton:hover {
  color: white;
  background-color: var(--lblue);
  cursor: pointer;
}
.inhibitorphotos {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 140px;
  right: -330px;
}
.granulephoto {
  width: 450px;
}
.solubles {
  background-color: #817d7d;
  background-color: white;
  padding: 6rem 0;
  top: -5px;
  background-image: url("../assets/_images/plantdrop2.png");
  background-image: url("../assets/_images/plant.jpg");
  /* background-size: cover; */
  background-position: 100%;
  background-position-x: 690px;
  background-position-y: 30px;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  padding-bottom: 8rem;
}
.solublestag {
  position: absolute;
  right: -10px;
  bottom: 150px;
  width: 500px;
  height: 70px;
  border-radius: 0.25rem;
  background-color: var(--blue);
  border: none;
}
.solublestagbutton {
  background-color: transparent;
  height: 100%;
  width: 100%;
  color: white;
  text-align: left;
  padding-left: 2rem;
  font-family: "Noto Sans", sans-serif;
  font-weight: bold;
  font-size: 1.2rem;
  border-radius: 0.25rem;
  border: none;
}
.solublestagbutton:hover {
  cursor: pointer;
  background-color: var(--lblue);
}
.solubles-wrapper {
  width: 80%;
  margin: 0 auto;
  min-height: 1000px;
  font-family: "Noto Sans", sans-serif;
  /* color: white; */
  /* color: #817d7d; */
}
.solubles-wrapper h1 {
  font-size: 3rem;
  padding-top: 2rem;
}
.solubles-wrapper h2 {
  color: #868383;
  font-size: 2rem;
  padding-top: 1.8rem;
}
.solubles-wrapper p {
  font-family: "Montserrat", sans-serif;
  font-size: 1.2rem;
  font-weight: 500;
  width: 45%;
  padding-top: 1.5rem;
  line-height: 1.9rem;
}
.solublecards {
  font-family: "Montserrat", sans-serif;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 70%;
}

.solublecards-left {
  display: flex;
  flex-basis: 50%;
  flex-direction: column;
}
.solublecards-right {
  display: flex;
  flex-basis: 50%;
  flex-direction: column;
  /* text-align: right; */
}
.solublecard {
  padding: 3.5rem 0 0 0;
}
.solublecard h1 {
  font-size: 1.2rem;
  color: var(--blue);
  padding-top: 0;
}
.solublecard ul {
  list-style: none;
  padding: 0;
}
.solublecard ul li {
  line-height: 1.65rem;
  display: flex;
}
.solublecard ul li:hover {
  cursor: pointer;
  font-weight: 500;
  /* background-color: rgb(201, 199, 199); */
}
.other {
  width: 100%;
  background-color: rgb(111, 111, 139);
}
.other-wrapper {
  width: 80%;
  margin: 0 auto;
  min-height: 800px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.othercol {
  display: flex;
  flex-direction: column;
  width: 360px;
  /* flex-basis: 33%; */
}
.othertitle {
  font-family: "Noto Sans", sans-serif;
  padding-top: 7rem;
  font-size: 3rem;
  color: white;
  margin-bottom: 0;
}
.otherbutton {
  padding: 1.2rem 2rem;
  width: 100%;
  margin-top: 2rem;
  border-radius: 0.25rem;
  border: none;
  font-family: "Noto Sans", sans-serif;
  font-weight: 400;
  font-size: 1rem;
  /* text-align: left; */
  background-color: rgb(70, 70, 70);
  color: white;
}
.otherbutton:hover {
  background-color: rgb(223, 223, 223);
  background-color: rgb(131, 131, 131);
  cursor: pointer;
}
.othercol p {
  color: white;
  font-family: "Montserrat", sans-serif;
  font-size: 1.1rem;
  height: 30px;
  /* width: 70%; */
}
.othercol ul {
  padding-left: 20px;
}
.othercol ul li {
  color: white;
  font-family: "Montserrat", sans-serif;
  font-size: 1rem;
  line-height: 1.75rem;
}

.navbar {
  height: 150px;
  /* background: black; */
  /* border-bottom: 0.5px white dashed; */
}
.navcontainer {
  width: 80%;
  height: 100%;
  margin: 0 auto;
  display: flex;
  align-items: baseline;
  padding-top: 3rem;
  padding-bottom: 0;
}
.navlogo {
  height: 70px;
}
.navlogo2 {
  width: 140px;
}
.cookiebuttonwrapper {
  display: flex;
  flex: 10%;
  flex-direction: column;
  padding: 1rem 0;
}
@keyframes cookieanimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.inhibtm {
  font-size: 0.7rem;
}
#dropdown3 ul ul li,
#dropdown ul ul li {
  display: flex;
  flex-direction: row;
}
.lgnav {
  margin-left: 5px;
  margin-right: 5px;
}
.bar {
  display: block;
  width: 25px;
  height: 3px;
  margin: 5px auto;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  background-color: white;
}
.bar2 {
  display: block;
  width: 25px;
  height: 3px;
  margin: 5px auto;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  background-color: black;
}
.mobile {
  display: none !important;
}
.hamshow {
  display: block !important;
}
.hamhide {
  display: none !important;
}
.mobilelmar {
  margin-left: 0.3rem;
}
.mobilermar {
  margin-right: 0.3rem;
}
.hamburger:hover {
  cursor: pointer;
}
.closemobilemenu:hover {
  cursor: pointer;
}

.mobilemenu {
  position: absolute;
  display: none;
  width: 70%;
  min-height: 100vh;
  left: 100%;
  z-index: 10;
  top: 60px;
  /* background-color: white; */
  /* background-color: var(--blue); */
  background-color: rgba(0, 0, 0, 0.8);
  font-family: "Noto Sans JP", sans-serif;
}
.mobilemenu ul {
  list-style: none;
  background-color: black;
  display: flex;
  flex-direction: column;
}
.mobilemenu ul li {
  display: flex;
  flex-direction: row;
  color: white;
  align-items: center;
  /* border-top: white; */
  height: 50px;
  border-bottom: 1px dashed white;
}
.mobilemenu ul li a {
  color: white;
  text-decoration: none;
  /* height: 80px; */
}
.mobilemenu ul a {
  text-decoration: none;
}
.mobilemenu ul li:hover {
  cursor: pointer;
}
.mobilemenu ul ul {
  padding-left: 0;
}
.mobilemenusub li {
  padding-left: 1rem;
}
.cookiecontainer {
  left: 7% !important;
}

@media only screen and (max-width: 1700px) {
  .valuecard {
    flex-basis: 48%;
  }
}
@media only screen and (max-width: 1600px) {
  .solublecards {
    width: 80%;
  }
}

@media only screen and (max-width: 1450px) {
  #dropdown3 ul ul li,
  #dropdown ul ul li {
    font-size: 0.75rem;
  }
  .lgnav {
    display: none;
  }
  .solubles {
    background-position-x: 490px;
  }
  .solublecards {
    width: 80%;
  }
  .solublestag {
    width: 350px;
  }
  .othercol {
    width: 300px;
  }
  .othercol p {
    height: 50px;
  }
  .cookiecontainer {
    left: 6% !important;
  }
}

@media only screen and (max-width: 1370px) {
  .valuecard {
    flex-basis: 100%;
  }
  .valuecard-text {
    flex-basis: 75%;
    margin-left: 4rem;
  }
  .inhibitorcard {
    flex-basis: 80%;
  }

  .solublecards {
    width: 90%;
  }
  .solubles-wrapper p {
    width: 55%;
  }
  .inhibitorphotos {
    top: 250px;
  }
}
@media only screen and (max-width: 1245px) {
  .other-wrapper {
    flex-direction: column;
  }
  .othercol {
    width: 100%;
    /* margin-bottom: 5rem; */
    height: 640px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-bottom: 1px dashed rgb(172, 172, 207);
  }
  .othercol:last-child {
    border: none;
    margin-bottom: 1rem;
  }

  .othertitle {
    padding-top: 0;
    margin-top: 0;
  }
  .othercol p {
    width: 100%;
    height: 20px;
  }
  .otherbutton {
    margin-top: 1rem;
    width: 50%;
  }
  .organic {
    margin-bottom: 8rem;
  }
  .cookiecontainer {
    left: 5% !important;
  }
}
@media only screen and (max-width: 1110px) {
  .nav #dropdown3,
  .nav2 #dropdown {
    width: 90vw;
    padding: 1rem 3rem;
    padding-top: 3rem;
    left: -340px;
  }
  .lgnavtxt {
    display: none;
  }
  .wesolvenavlist div:nth-child(1) {
    padding-right: 2rem;
  }
  .sollgtxt {
    display: none;
  }
  .solublestag {
    width: 270px;
  }
  .solubles-wrapper p {
    width: 65%;
  }
}
@media only screen and (max-width: 1000px) {
  .mainhome {
    background-image: url("../assets/_images/growingplant1000px.jpg");
  }
  .notmobile {
    display: none;
  }
  .mobile {
    display: block !important;
  }
  .hideclosemobmenu {
    display: none !important;
  }
  .hideham {
    display: none !important;
  }
  .navcontainer2 {
    padding-top: 1rem;
  }
  .navcontainer {
    width: 80%;
    justify-content: space-between;
  }
  .solublecards {
    width: 100%;
  }
  .solublestag {
    width: 240px;
  }
  .cookiecontainer {
    left: 4% !important;
    height: 220px !important;
  }
}
@media only screen and (max-width: 850px) {
  .taghome h1 {
    font-size: 3.5rem;
  }
  .taghome h5 {
    font-size: 1.2rem;
    line-height: 1.7rem;
    min-width: 450px;
  }
  .mainbutton {
    font-size: 1rem;
    padding: 1.3rem 3.2rem;
  }
  .solublestag {
    bottom: 650px;
  }
  .othercol p {
    margin-bottom: 0.5rem;
    height: auto;
  }
  .inhibiticon {
    margin-right: 1rem;
  }
  .cookiecontainer {
    left: 3% !important;
    height: 270px !important;
    flex-direction: column !important;
    flex-wrap: nowrap !important;
  }
  .cookiecontainer p {
    font-size: 0.9rem !important;
  }
  .cookiebuttonwrapper {
    padding-top: 0 !important;
    flex-direction: row !important;
  }
  .cookiebuttonwrapper button {
    font-size: 0.9rem !important;
    margin-top: 0.2rem !important;
  }
  .contentcookie {
    flex: 1 !important;
    width: 100% !important;
    margin-bottom: 0 !important;
  }
}
@media only screen and (max-width: 750px) {
  .socialicons {
    display: none;
  }
  .solublestag {
    display: none;
  }
  .solublecards {
    width: 100%;
  }
  .solublecards-right {
    flex-basis: 40%;
  }
  .valuecard {
    align-items: center;
    flex-flow: column-reverse;
    padding-bottom: 5rem;
    margin-right: 0;
    border-bottom: dashed 1px rgb(163, 145, 119);
    border-radius: 0;
    min-width: 0px;
  }
  .valuecard:nth-child(2n-1) {
    margin-right: 0;
  }
  .valuecard:last-child {
    border: none;
    padding-bottom: 0;
  }
  .valuecard-text {
    margin-left: 0;
    padding-right: 0;
  }
  .valuecard-text h2,
  .inhibitorcards-wrapper h2,
  .solubles-wrapper h2 {
    font-size: 2rem;
  }
  .valueicon {
    width: 80px;
  }
  .otherbutton {
    width: 60%;
  }
}
@media only screen and (max-width: 650px) {
  .taghome h1 {
    font-size: 3rem;
  }
  .taghome h5 {
    font-size: 1.1rem;
    line-height: 1.7rem;
    min-width: 450px;
  }
  .mainbutton {
    font-size: 0.9rem;
    padding: 1.1rem 2.3rem;
  }
  .valuecard-text h2 {
    margin-bottom: 1.3rem;
  }
  .valuecard-text h2,
  .inhibitorcards-wrapper h2,
  .solubles-wrapper h2 {
    font-size: 1.7rem;
  }
  .otherbutton {
    width: 70%;
  }
  .inhibitorphotos {
    display: none;
  }
  .inhibitorcards-wrapper h3,
  .solubles-wrapper p {
    font-size: 1rem;
    line-height: 1.7rem;
    width: 100%;
    margin-bottom: 3rem;
  }
  .solubles-wrapper p {
    margin-bottom: 0;
  }
  .inhibitorcards {
    width: 100%;
  }
  .inhibitorcard {
    padding-right: 0;
    flex-basis: 100%;
    min-width: 0;
    padding-bottom: 7rem;
    margin-bottom: 5rem;
    border-bottom: dashed 1px gray;
  }
  .inhibitorcard:last-child {
    border: none;
    margin-bottom: 0;
  }
  .inhibitorbutton {
    position: relative;
    margin-top: 4rem;
    align-self: center;
    width: 100%;
  }
  .inhibitorcard-bottom a {
    align-self: center;
    width: 100%;
  }
  .inhibitorcard-bottom {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }
  .cookiecontainer {
    left: 1% !important;
  }
}
@media only screen and (max-width: 550px) {
  .mainhome {
    background-image: url("../assets/_images/growingplant550px.jpg");
  }
  .taghome h1 {
    font-size: 2.1rem;
    margin-bottom: 3rem;
  }
  .taghome h5 {
    font-size: 1.2rem;
    line-height: 1.7rem;
    min-width: 330px;
  }
  .homebuttons {
    flex-wrap: wrap;
    width: 100%;
  }
  .homebuttons a {
    width: 100%;
  }
  .mainbutton {
    font-size: 0.9rem;
    padding: 1.1rem 2.3rem;
    margin-bottom: 1rem;
    width: 100%;
  }
  .homepage {
    min-height: 400px;
    height: 65vh;
  }
  .wgvalues-wrapper h1,
  .othertitle,
  .inhibitorcards-wrapper h1,
  .solubles-wrapper h1 {
    font-size: 2.3rem;
  }
  .valuecard-text h2,
  .inhibitorcards-wrapper h2,
  .solubles-wrapper h2 {
    font-size: 1.4rem;
  }
  .otherbutton {
    width: 85%;
  }
  .solublecard h1 {
    font-size: 1.2rem;
  }
  .cookiecontainer {
    left: 0% !important;
  }
}
@media only screen and (max-width: 420px) {
  .taghome h1 {
    font-size: 2rem;
  }
  .wgvalues-wrapper h1 {
    font-size: 2.3rem;
  }
  .otherbutton {
    width: 100%;
    margin-top: 2rem;
  }
  .cookiebuttonwrapper {
    padding-top: 0 !important;
    flex-direction: column !important;
  }
  .cookiecontainer {
    height: 390px !important;
    width: 75% !important;
  }
}
