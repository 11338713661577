.weare {
  min-height: 1150px;
  width: 100%;
  /* background-color: gray; */
  /* background-image: url("../assets/_images/grasss.jpg");
  background-size: contain; */
  /* background-color: rgb(235, 235, 235); */
}
.wearevideo {
  width: 1150px;
  width: 980px;
  width: 1200px;
  /* width: 70%; */
  height: 800px;
  height: 930px;
  position: absolute;
  object-fit: cover;
  right: -15%;
  right: -10%;
  /* right: -5%; */
  top: 1.5%;
  /* top: 5%; */
  clip-path: url(#svgPath);
}
.wearepillars {
  padding-top: 10rem;
  position: relative;
  min-height: 900px;
}
.wearemap {
  margin-top: 2rem;
  padding-top: 11rem;
  /* padding-bottom: 5rem; */
  position: relative;
  min-height: 900px;
  /* background-color: rgb(230, 230, 230); */
  background-image: linear-gradient(white 0%, #abc4e66b 15%, white 100%);
}
.wrmap-wrapper {
  width: 80%;
  margin: 0 auto;
}
.wrmap {
  background-image: url("../assets/_images/mapbg4.svg");
  /* width: 100%; */
  margin: 0 auto;
  height: 880px;
  /* height: 1700px; */
  /* clip-path: url(#mappath); */
  /* width: 1300px; */
  /* height: 1400px; */
  background-size: contain;
  background-repeat: no-repeat;
  /* opacity: 0.8; */
  object-fit: cover;
  position: relative;
  /* margin-top: 10rem; */

  /* position: absolute; */
}
.usaofficepin {
  position: absolute;
  font-family: "Bebas Neue", sans-serif;
  /* color: var(--blue); */
  /* font-weight: 800; */
  font-size: 2rem;
  width: 400px;
  border-bottom: 3px solid var(--blue);
  top: 33%;
  left: 9%;
  transform: translate(-50%, -50%);
}
.usaofficepin p {
  margin-bottom: 0;
  margin-top: 0;
}
.usaofficepin-line {
  display: flex;
  flex-direction: row;
}
.brazilofficepin {
  position: absolute;
  font-family: "Bebas Neue", sans-serif;
  color: var(--blue);
  /* font-weight: 800; */
  font-size: 2rem;
  width: 400px;
  border-bottom: 3px solid var(--blue);
  top: 61%;
  left: 18%;
  transform: translate(-50%, -50%);
}
.brazilofficepin p {
  margin-bottom: 0;
}
.wrpillars-wrapper {
  width: 80%;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: right;
}
.wrpillarsright {
  display: flex;
  flex-basis: 60%;
}
.wrpillars {
  display: flex;

  justify-content: space-between;
  flex-wrap: wrap;
}
.wrpillar {
  display: flex;
  flex-direction: column;
  flex-basis: 45%;
  margin-bottom: 5rem;
}
.wrpillar h2 {
  font-family: "Noto Sans", sans-serif;
  font-size: 2rem;
  margin: 1.65rem 0;
}
.wrpillar p {
  font-family: "Montserrat", sans-serif;
  line-height: 1.8rem;
  margin: 1rem 0;
}
.wearepillarimage {
  background-image: url("../assets/_images/farmer2.jpg");
  background-image: url("../assets/_images/fruits.jpg");
  /* background-size: 90%; */
  /* background-size: 180%; */
  background-size: 120%;
  background-repeat: no-repeat;
  background-position: -60% -10%;
  clip-path: url(#svgPath2);
  width: 1300px;
  height: 1400px;
  object-fit: cover;
  position: absolute;
  left: -35%;
}
.weare-wrapper {
  width: 80%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 1050px;
}
.tagweare {
  color: rgb(51, 51, 51);

  width: 50%;
  font-family: "Noto Sans", sans-serif;
  font-weight: 700;
  /* padding-top: 50px; */
  font-size: 2.3rem;
  display: flex;
  flex-direction: column;
  flex-basis: 50%;
}
.tagweare h1 {
  color: var(--blue);
  font-size: 3.5rem;
  margin-top: 0;
}
.tagweare p {
  font-family: "Noto Sans", sans-serif;
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 2.5rem;
  /* color: var(--blue); */
  /* margin-bottom: 8rem; */
  width: 70%;
}
.ourmission {
  min-height: 500px;
  background-image: url("../assets/_images/field.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}
.ourmissionouter {
  width: 100%;
  background-color: rgba(0, 0, 0, 0.43);
  min-height: 500px;
}
.ourmissionwrapper {
  width: 80%;
  margin: 0 auto;
  color: white;
  display: flex;
  flex-direction: column;
  font-family: "Noto Sans", sans-serif;
  padding-top: 4rem;
}
.ourmissionwrapper h1 {
  font-size: 1.5rem;
  margin-bottom: 0;
}
.wrpillarsdiv h1 {
  font-size: 1.5rem;
  margin-bottom: 0;
  font-family: "Noto Sans", sans-serif;
  color: var(--blue);
  margin-bottom: 2rem;
}
.wrmap-wrapper h1 {
  font-size: 1.5rem;
  margin-bottom: 0;
  font-family: "Noto Sans", sans-serif;
  color: var(--blue);
  margin-bottom: 2rem;
}
.weareteam-wrapper h1 {
  font-size: 1.5rem;
  margin-bottom: 0;
  font-family: "Noto Sans", sans-serif;
  color: var(--blue);
  margin-bottom: 2rem;
}
.wrmap-wrapper h2 {
  font-family: "Noto Sans", sans-serif;
  font-size: 3rem;
}
.weareteam-wrapper h2 {
  font-family: "Noto Sans", sans-serif;
  font-size: 2.5rem;
}
.ourmissionwrapper h2 {
  font-size: 3rem;
  margin-bottom: 1rem;
  /* color: var(--green); */
}
.ourmissionwrapper p {
  width: 40%;
  font-size: 1.3rem;
  font-family: "Montserrat", sans-serif;
  line-height: 2rem;
}
.weareteam {
  min-height: 900px;
}
.weareteam-wrapper {
  width: 80%;
  margin: 0 auto;
}
.trail {
  font-family: "Teko", sans-serif;
  font-size: 1.5rem;
  color: rgb(167, 167, 167);
  display: flex;
  flex-basis: 20%;
  flex-direction: row;
  align-items: flex-start;
}
.breadcrumb {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.breadcrumb p {
  margin-right: 0.5rem;
  margin-left: 0.5rem;
}
.breadcrumb p:first-child {
  margin-left: 0;
}

@media only screen and (max-width: 1771px) {
  .weare {
    min-height: 1100px;
  }
  .wearevideo {
    width: 1100px;
    height: 850px;
    top: 2%;
  }
}

@media only screen and (max-width: 1651px) {
  .weare {
    min-height: 1050px;
  }
  .weare-wrapper {
    height: 950px;
  }
  .wearevideo {
    width: 1000px;
    height: 800px;
    top: 2%;
  }
  .tagweare h1 {
    font-size: 3.5rem;
  }
  .tagweare p {
    font-size: 1.4rem;
  }
  .trail {
    flex-basis: 15%;
  }
}
@media only screen and (max-width: 1551px) {
  .wearevideo {
    width: 950px;
    height: 770px;
    top: 2%;
  }
  .weare {
    min-height: 1000px;
  }
  .weare-wrapper {
    height: 900px;
  }
}

@media only screen and (max-width: 1390px) {
  .weare {
    min-height: 970px;
  }
  .weare-wrapper {
    height: 900px;
  }
  .wearevideo {
    width: 880px;
    height: 730px;
    top: 2%;
  }
  .trail {
    flex-basis: 20%;
  }
  .tagweare h1 {
    font-size: 3.5rem;
  }
  .tagweare p {
    font-size: 1.4rem;
    line-height: 2.5rem;
  }
  .wearepillarimage {
    height: 1000px;
    width: 980px;
    top: 12%;
    left: -40%;
  }
  .wearemap {
    padding-top: 0;
  }
  .wrmap {
    height: 600px;
  }
  .ourmissionwrapper p {
    width: 60%;
  }
}

@media only screen and (max-width: 1310px) {
  .wearevideo {
    clip-path: none;
    position: absolute;
    left: 0;
    top: 0;
    width: 140%;
  }
  .weare {
    min-height: 950px;
    /* height: 700px; */
    width: 100%;
    background-color: gray;
    background-color: black;
  }
  .weare-wrapper {
    /* height: 100%; */
  }
  .tagweare h1 {
    color: white;
  }
  .tagweare p {
    color: white;
    width: 100%;
  }
  .tagweare {
    width: 65%;
  }
  .wearepillarimage {
    clip-path: none;
    position: absolute;
    width: 130%;
    left: -250px;
    display: none;
  }
  .wearepillars {
    /* background-image: url("../assets/_images/fruits.jpg");
    background-size: cover; */
  }
  .wrpillars-wrapper {
    justify-content: left;
  }
  .wrpillarsright {
    flex-basis: 100%;
  }
  svg {
    display: none;
  }
}
@media only screen and (max-width: 1110px) {
  .wearevideo {
    width: 180%;
  }
  .tagweare {
    width: 75%;
  }
  .ourmissionwrapper p {
    width: 80%;
  }
}
@media only screen and (max-width: 950px) {
  .ourmissionwrapper p {
    width: 100%;
  }
  .ourmission,
  .ourmissionouter {
    min-height: 600px;
  }
  .ourmissionouter {
    padding-top: 7rem;
  }
}
@media only screen and (max-width: 850px) {
  .wearevideo {
    width: 240%;
  }
  .tagweare {
    width: 85%;
  }
  .wrpillar {
    flex-basis: 100%;
  }
  .wearemap {
    background-color: rgb(230, 230, 230);
    background-image: none;
    padding-top: 6rem;
    min-height: 600px;
  }
  .wrmap-wrapper h2 {
    font-size: 2rem;
  }
  .wrmap-wrapper {
    height: auto;
  }
}

@media only screen and (max-width: 790px) {
  .ourmission,
  .ourmissionouter {
    min-height: 700px;
  }
}

@media only screen and (max-width: 650px) {
  .tagweare {
    width: 95%;
  }
  .tagweare h1 {
    font-size: 3rem;
  }
  .tagweare p,
  .ourmissionwrapper p {
    font-size: 1.1rem;
    line-height: 1.7rem;
  }
  .ourmission,
  .ourmissionouter {
    min-height: 750px;
  }
  .wrmap {
    height: 200px;
  }
}
