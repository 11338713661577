.modal {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 50;
}

.modal-main {
  position: fixed;
  background: white;
  width: 30%;
  height: 70vh;
  overflow-y: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 3rem;
  box-shadow: 3px 3px 30px rgb(0, 0, 0);
  border-radius: 3rem;
}
.modal-main h1 {
  font-family: "Noto Sans", sans-serif;
  color: #93ad3c;
  text-align: center;
}
.modal-main p {
  font-family: "Montserrat", sans-serif;
  margin: 0 auto;
  text-align: center;
  line-height: 1.7rem;
  width: 85%;
  margin-bottom: 1.5rem;
}
.modal-main button {
  font-family: "Noto Sans", sans-serif;
  padding: 0.8rem 4.5rem;
  border: none;
  background-color: #4441a2;
  color: white;
  border-radius: 0.5rem;
  font-size: 1.2rem;
  width: 250px;
  margin: 0 auto;
  margin-top: 1.5rem;
}
.modal-main form {
  width: 85%;
  margin: 0 auto;
}
.form-group {
  margin-bottom: 0.5rem;
  display: flex;
  flex-direction: column;
}
.form-group label {
  margin-bottom: 0.5rem;
  font-family: "Noto Sans", sans-serif;
}
.form-group input {
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  border: solid 1px;
  font-family: "Montserrat", sans-serif;
  font-size: 1rem;
}
.form-group textarea {
  height: 40px;
  border-radius: 0.5rem;
  font-family: "Montserrat", sans-serif;
  font-size: 1rem;
  padding: 0.5rem 1rem;
}

.display-block {
  display: block;
}

.display-none {
  display: none;
}

@media only screen and (max-width: 1700px) {
  .modal-main {
    width: 40%;
  }
}

@media only screen and (max-width: 1280px) {
  .modal-main {
    width: 50%;
  }
}
@media only screen and (max-width: 1010px) {
  .modal-main {
    width: 50%;
  }
  .modal-main h1 {
    font-size: 1.7rem;
  }
  .modal-main p {
    font-size: 0.9rem;
    line-height: 1.4rem;
  }
  .form-group label,
  .form-group input {
    font-size: 0.9rem;
  }
  .modal-main button {
    font-size: 1rem;
  }
}
@media only screen and (max-width: 900px) {
  .modal-main {
    width: 60%;
  }
}
@media only screen and (max-width: 750px) {
  .modal-main {
    width: 65%;
  }
}
@media only screen and (max-width: 550px) {
  .modal-main {
    width: 80%;
    height: auto;
    padding: 3rem 1.5rem;
  }
  .modal-main form {
    width: 100%;
  }
  .form-group {
    flex-direction: row;
  }
  .form-group label {
    display: flex;
    flex-basis: 20%;
    min-width: 60px;
  }
  .form-group input {
    display: flex;
    flex-basis: 70%;
  }
  .form-group textarea {
    display: flex;
    flex-basis: 70%;
  }
  .form .modal-main h1 {
    font-size: 1.4rem;
  }
  .modal-main p {
    font-size: 0.8rem;
    line-height: 1.4rem;
    width: 100%;
  }
  .form-group label,
  .form-group input {
    font-size: 0.8rem;
  }
  .modal-main button {
    font-size: 0.9rem;
    min-width: 100%;
  }
}
