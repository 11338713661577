.volitmain {
  min-height: 1050px;
  width: 100%;
}
.volitmainwrapper {
  width: 80%;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  height: 1050px;
}
.vmaintag {
  display: flex;
  flex-basis: 30%;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.vlogo {
  height: 80px;
  margin-bottom: 3rem;
}
.vmaintag p {
  font-family: "Montserrat", sans-serif;
  font-size: 1.3rem;
  line-height: 2.5rem;
}
.vmainimg {
  height: 1000px;
  display: flex;
  flex-basis: 70%;
  justify-content: flex-end;
  align-items: center;
}
.vimg {
  height: 800px;
}
.volitsection2 {
  min-height: 400px;
  background-color: rgb(241, 241, 241);
  width: 100%;
}
.volitsection2-wrapper {
  width: 80%;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 400px;
  justify-content: space-between;
}
.vs2msg {
  display: flex;
  flex-direction: row;
  /* flex-basis: 40%; */
  width: 670px;
  justify-content: space-between;
  height: 270px;
}
.counters1 {
  display: flex;
  flex-direction: column;
  flex-basis: 25%;
  min-width: 160px;
}
.counters1 h2 {
  font-family: "Noto Sans", sans-serif;
  font-weight: 600;
  font-size: 2rem;
  color: rgb(177, 177, 177);
  width: 100%;
  text-align: center;
  line-height: 0.5rem;
}
.counter1 {
  font-family: "Noto Sans", sans-serif;
  font-weight: 600;
  font-size: 5rem;
  color: rgb(177, 177, 177);
  flex-basis: 25%;
  text-align: right;
}
.vs2prot {
  display: flex;
  flex-direction: column;
  flex-basis: 65%;
  height: 200px;
}
.vs2prot h2 {
  font-family: "Noto Sans", sans-serif;
  margin-top: 1rem;
}
.vs2prot p {
  font-family: "Montserrat", sans-serif;
  line-height: 1.75rem;
  font-size: 1.1rem;
}
.volitsection3 {
  min-height: 1380px;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  background-color: rgb(231, 231, 231);
  /* background-color: rgb(216, 216, 216); */
  /* background-image: linear-gradient(
    rgb(231, 231, 231) 25%,
    rgb(231, 231, 231) 85%,
    white 100%
  );  */
  /* background-color: white; */
}

.volitsection3-wrapper {
  width: 80%;
  margin: 0 auto;
  height: 1120px;
}
.volitsection3 img {
  position: absolute;
  height: 800px;
  top: 300px;
  left: -20px;
}
.v3item1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 4rem;
}
.v3item1line {
  border-top: 1px solid #30353b;
  padding-top: 10px;
  padding-bottom: 10px;
  margin: 40px auto 0;
  width: 50%;
  position: relative;
}
.v3item1line:before,
.v3item1line:after {
  content: "";
  width: 15px;
  height: 15px;
  background: #30353b;
  border-radius: 35px;
  position: absolute;
  top: -8px;
}
.v3item1text {
  margin-left: 2rem;
  font-family: "Montserrat", sans-serif;
  font-size: 1.2rem;
}
.v3item1text p {
  line-height: 1.9rem;
  font-size: 1.1rem;
  width: 700px;
}
.v3item1text h2 {
  font-size: 1.6rem;
  font-family: "Noto Sans", sans-serif;
}
.volitsection31 {
  min-height: 600px;
  width: 100%;
  background-color: black;
  background-image: url("../assets/_images/volsurf.svg");
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
}
.volitsection31-wrapper {
  width: 80%;
  margin: 0 auto;
  padding: 6rem 0;
}
.volitsection31-wrapper h2 {
  color: white;
  font-family: "Big Shoulders Text", sans-serif;
  font-size: 2.7rem;
  font-weight: 400;
}
.volitsection31-wrapper p {
  color: white;
  width: 30%;
  font-family: "Big Shoulders Text", sans-serif;
  line-height: 2.6rem;
  font-size: 1.7rem;
  font-weight: 200;
  margin-bottom: 4rem;
}
.volitsection31-wrapper button {
  background-color: white;
  border: none;
  border-radius: 0.25rem;
  color: black;
  padding: 0.7rem 3rem;
  font-family: "Teko", sans-serif;
  font-size: 1.8rem;
}
.volitsection31-wrapper button:hover {
  cursor: pointer;
  background-color: rgb(218, 240, 255);
}
.volitsection4 {
  min-height: 1050px;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  /* background-color: rgb(214, 214, 214); */
}
.volitsection4-wrapper {
  width: 80%;
  margin: 0 auto;
}
.volitsection4-wrapper h1 {
  font-size: 1.5rem;
  font-family: "Noto Sans", sans-serif;
  color: var(--blue);
  margin-top: 2rem;
}
.volitsection4-wrapper h2 {
  font-size: 2.5rem;
  font-family: "Noto Sans", sans-serif;
  margin-bottom: 5rem;
}
.inhibcards {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 5rem;
}
.inhibcards a {
  display: flex;
  flex-basis: 26%;
  text-decoration: none;
  color: rgb(0, 0, 0);
}
.inhibcard {
  display: flex;
  flex-direction: column;

  height: 380px;
  padding: 3rem;
  border: 1px solid rgb(214, 214, 214);
  border-radius: 2rem;

  /* background-color: red; */
}
.inhibcard:hover {
  cursor: pointer;
  background-color: rgb(248, 248, 248);
}
.inhibcardtitle {
  min-height: 60px;
}
.inhibnfxlogo {
  height: 45px;
}
.inhibintlogo {
  height: 60px;
}
.inhibnurlogo {
  height: 60px;
}
.inhibcard p {
  font-family: "Montserrat", sans-serif;
  font-size: 1.1rem;
  line-height: 1.6rem;
  margin-top: 2rem;
  min-height: 230px;
}
.inhiblegend {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 60%;
  /* margin: 0 auto; */
}
.counters1 .vcounter2 {
  width: 100%;
  text-align: right;
}
.v3itemshort {
  margin-left: 10%;
}
.v3itemlong {
  margin-left: 23%;
}
.inhibcardbutton {
  display: none;
}

@media only screen and (max-width: 1850px) {
  .volitmain {
    min-height: 950px;
  }
  .volitmainwrapper {
    height: 950px;
  }
}
@media only screen and (max-width: 1750px) {
  .vimg {
    /* width: 750px; */
    height: 720px;
  }
  .vmainimg {
    height: 890px;
  }
}
@media only screen and (max-width: 1650px) {
  .vmaintag p {
    font-size: 1.2rem;
    line-height: 2.2rem;
  }
  .vmaintag .iconmsg {
    font-size: 1.1rem;
  }
}
@media only screen and (max-width: 1550px) {
  .vimg {
    /* width: 750px; */
    height: 670px;
    margin-left: 2rem;
  }
}
@media only screen and (max-width: 1450px) {
  .vimg {
    height: 630px;
  }
  .vmaintag p {
    font-size: 1.1rem;
    line-height: 2rem;
  }
  .vmaintag .iconmsg {
    font-size: 1rem;
  }
  .vmaintag {
    flex-basis: 55%;
    min-width: 300px;
  }
  .vmainimg {
    flex-basis: 40%;
  }
  .inhibnfxlogo {
    height: 40px;
  }
  .inhibintlogo {
    height: 55px;
  }
  .inhibnurlogo {
    height: 55px;
  }
  .inhibcardtitle {
    min-height: 50px;
    margin-top: 1rem;
  }
  .inhibcard {
    height: 440px;
    padding: 3rem 2.5rem;
  }
}
@media only screen and (max-width: 1350px) {
  .counter1 {
    font-size: 4.5rem;
  }
  .vs2prot {
    margin-left: 2rem;
  }
  .volitsection2 {
    min-height: 450px;
  }
  .volitsection31-wrapper p {
    width: 50%;
  }
}
@media only screen and (max-width: 1200px) {
  .v3item1line {
    display: none;
  }
  .v3item1text p {
    width: auto;
  }
  .v3itemshort,
  .v3itemlong {
    margin-left: 25%;
  }
  .v3item1:last-child {
    margin-bottom: 6rem;
  }
  .v3item1:first-child {
    margin-top: 6rem;
  }
  .volitsection3 {
    min-height: auto;
  }
  .volitsection3-wrapper {
    height: auto;
  }
  /* .inhibcards {
    flex-direction: column;
  }
  .inhibcard {
    height: auto;
    margin-bottom: 3rem;
  }
  .inhibcard p {
    min-height: auto;
    margin-bottom: 2rem;
  } */
  .inhibnfxlogo {
    height: 33px;
  }
  .inhibintlogo {
    height: 48px;
  }
  .inhibnurlogo {
    height: 48px;
  }
  .inhibcard p {
    font-size: 1rem;
    min-height: 80px;
    margin: 1.5rem 0;
  }
  .inhibcardmobile {
    display: none;
  }
  .inhibcard {
    height: auto;
    padding: 2rem 2rem;
  }
  .volitsection4-wrapper .inhibicon {
    height: 50px;
  }
  .volitsection4-wrapper h2 {
    font-size: 2.2rem;
    margin-bottom: 3rem;
  }
  .volitsection4-wrapper h1 {
    margin-top: 6rem;
  }

  .volitsection4 {
    min-height: 790px;
  }
  .inhibcards {
    margin-bottom: 6rem;
  }
  .inhibcardtitle {
    min-height: 50px;
  }
}
@media only screen and (max-width: 1150px) {
  .volitsection2-wrapper {
    flex-direction: column;
  }
  .vs2msg {
    width: 100%;
    margin-top: 6rem;
    min-width: 0;
  }
  .counters1 {
    max-width: 165px;
  }
  .vs2prot {
    flex-basis: 75%;
    margin-left: 4rem;
    min-width: 0;
  }
  .volitsection2 {
    min-height: 730px;
  }
  .vs2prot h2 {
    width: 500px;
    min-width: 0;
  }
  .vs2msg:last-child {
    margin-top: 4rem;
  }
  .volitsection31-wrapper p {
    width: 70%;
  }
}
@media only screen and (max-width: 1000px) {
  .v3itemshort,
  .v3itemlong {
    margin-left: 0;
  }
  .volitsection3-wrapper {
    background-color: rgba(45, 45, 45, 0.6);
    z-index: 1;
    width: 100%;
    height: auto;
    /* padding-top: 4rem; */
    /* margin-top: 9rem; */
    color: white;
  }
  .v3item1text {
    width: 80%;
    margin: 2rem auto;
  }
  .v3item1 {
    margin-bottom: 1.5rem;
  }
  .v3item1:last-child {
    margin-bottom: 4rem;
  }
  .v3item1:first-child {
    margin-top: 4rem;
  }
  .volitsection3 {
    min-height: 1300px;
  }
  .v3item1text p {
    width: auto;
  }
  .inhibnfxlogo {
    height: 30px;
  }
  .inhibintlogo {
    height: 45px;
  }
  .inhibnurlogo {
    height: 45px;
  }
  .volitsection4-wrapper .inhibicon {
    height: 45px;
  }
}
@media only screen and (max-width: 950px) {
  .vs2prot h2 {
    width: 100%;
  }
  .inhibcards {
    flex-direction: column;
  }
  .inhibcardmobile {
    display: inline;
  }
  .inhibcard {
    margin-bottom: 3rem;
  }
}
@media only screen and (max-width: 850px) {
  .volitmainwrapper {
    flex-direction: column;
    height: 1300px;
  }
  .vmaintag {
    /* min-height: 0; */
    flex-basis: 36%;
    padding-top: 7rem;
  }
  .vmainimg {
    width: 108%;
    margin-left: 0;
  }
  .vlogo {
    height: 70px;
  }
  .vmaintag .icontagfirst {
    margin-top: 2rem;
  }
  .volitsection2 {
    min-height: 780px;
  }
  .counter1 {
    font-size: 4rem;
  }
}
@media only screen and (max-width: 650px) {
  .volitsection2 {
    min-height: 820px;
  }
  .vmaintag p {
    font-size: 1rem;
    line-height: 1.8rem;
  }
  .vmaintag .iconmsg {
    font-size: 1rem;
  }
  .vlogo {
    height: 60px;
  }
  .volitmainwrapper {
    height: 1250px;
  }
  .vs2prot p {
    font-size: 1rem;
  }
  .vs2prot h2 {
    font-size: 1.4rem;
  }
  .counters1 {
    min-width: 140px;
  }
  .v3item1text p {
    font-size: 1rem;
  }
  .v3item1text h2 {
    font-size: 1.4rem;
  }
  .volitsection31-wrapper p {
    font-size: 1.4rem;
  }
  .volitsection31-wrapper button {
    font-size: 1.5rem;
  }
  .volitsection4-wrapper h2 {
    font-size: 1.4rem;
    margin-top: 3rem;
  }
}
@media only screen and (max-width: 550px) {
  .vs2msg {
    flex-direction: column;
    height: auto;
  }
  .counters1 {
    flex-direction: row;
  }
  .vs2prot {
    flex-basis: 100%;
    margin-left: 0;
  }
  .counter1 {
    font-size: 3.5rem;
  }
  .counters1 h2 {
    line-height: 2.6rem;
    margin-left: 1rem;
    margin-right: 1rem;
  }
  .counters1 {
    max-width: 230px;
  }
  .volitsection2 {
    min-height: 900px;
  }
  .counters1 .vcounter2 {
    text-align: left;
    margin-left: 0;
    width: 90px;
  }
}
@media only screen and (max-width: 450px) {
  .volitsection2 {
    min-height: 1000px;
  }
  .volitsection31-wrapper button {
    width: 100%;
  }
  .inhibcard {
    border: none;
    padding: 4rem 0;
    margin: 0;
    border-bottom: 1px dashed rgb(214, 214, 214);
    border-radius: 0;
    background-color: white;
  }
  .firstinhibcard {
    padding-top: 0;
  }
  .inhibcardbutton {
    display: block;
    margin-top: 2.5rem;
    padding: 1rem;
    border: none;
    background-color: var(--blue);
    color: white;
    border-radius: 0.4rem;
    font-size: 1rem;
  }
  .lastinhibcard {
    border-bottom: none;
  }
  .inhibnfxlogo {
    height: 40px;
  }
}
