.surfectbody {
  background-color: black;
  width: 100%;
  overflow-x: hidden;
}
.surfectmain {
  /* min-height: 900px; */
  /* background-color: red; */
  background-image: url("../assets/_images/spray1_3.png");
  background-repeat: no-repeat;
  /* background-size: 101%; */
  background-size: cover;
  width: 100%;
}
.surfectmain-wrapper {
  width: 80%;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  height: 920px;
}
.surfectmaintag {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.surfectmaintag h1 {
  color: white;
  font-family: "Big Shoulders Text", sans-serif;
  font-weight: 400;
  font-size: 3.5rem;
}
.surfectmaintag p {
  color: white;
  width: 40%;
  font-family: "Big Shoulders Text", sans-serif;
  line-height: 2.6rem;
  font-size: 1.7rem;
  font-weight: 200;
  margin-bottom: 4rem;
}
.surfect2 {
  min-height: 900px;
  /* background-color: red; */
  background-image: url("../assets/_images/spray2.png");
  background-repeat: no-repeat;
  background-size: 101%;
  width: 100%;
  position: relative;
  background-size: cover;
}
.surfecttrait1,
.surfecttrait2,
.surfecttrait3 {
  position: absolute;
  top: 25%;
  left: 30%;
  height: 80px;
  display: flex;
  flex-direction: row;
  width: 50%;
  justify-content: space-between;
}
.surfecttrait2 {
  top: 55%;
  left: 15%;
  width: 47%;
}
.surfecttrait3 {
  top: 85%;
  left: 20%;
}
.target2 {
  height: 60px;
}
.target3 {
  height: 50px;
}
.target4 {
  height: 20px;
}
.target5 {
  height: 70px;
}
.strait1text,
.strait2text,
.strait3text,
.strait4text,
.strait5text {
  color: white;
  margin-left: 2rem;
  font-family: "Big Shoulders Text", sans-serif;
  position: relative;
  bottom: 50%;
}
.strait1text h3,
.strait2text h3,
.strait3text h3,
.strait4text h3,
.strait5text h3 {
  font-size: 1.9rem;
  font-weight: 400;
  margin-bottom: 0;
}
.strait1text p,
.strait2text p,
.strait3text p,
.strait4text p,
.strait5text p {
  font-size: 1.3rem;
  font-weight: 200;
  width: 350px;
  margin-top: 0.5rem;
}
.target1line,
.target2line,
.target3line,
.target4line,
.target5line {
  width: 100%;
  border-bottom: 1px white solid;
  position: relative;
  bottom: 50%;
}
.target2line {
  bottom: 60%;
}
.target3line {
  bottom: 67%;
}
.target4line {
  bottom: 87%;
}
.target5line {
  bottom: 50%;
}
.surfect3 {
  min-height: 775px;
  /* background-color: red; */
  background-image: url("../assets/_images/spray3.png");
  background-repeat: no-repeat;
  background-size: 101%;
  width: 100%;
  background-size: cover;
  position: relative;
}
.surfecttrait4,
.surfecttrait5 {
  position: absolute;
  top: 20%;
  left: 10%;
  height: 80px;
  display: flex;
  flex-direction: row;
  width: 50%;
  justify-content: space-between;
}
.surfecttrait5 {
  top: 50%;
  left: 25%;
}
@media only screen and (max-width: 1400px) {
  .surfectmain {
    background-position-x: 50%;
    background-color: black;
  }
  .surfect2 {
    background-position: 40%;
  }
  .surfect3 {
    background-position-x: 60%;
  }
  .surfecttrait1,
  .surfecttrait2 {
    width: 60%;
  }
}
@media only screen and (max-width: 1250px) {
  .surfectmaintag p {
    width: 60%;
  }
}
@media only screen and (max-width: 1250px) {
  .surfecttrait1,
  .surfecttrait2 {
    width: 75%;
  }
  .surfecttrait1 {
    left: 20%;
  }
  .surfecttrait2 {
    left: 10%;
  }
}
@media only screen and (max-width: 780px) {
  .surfectmain-wrapper {
    height: 700px;
  }
  .surfectmaintag h1 {
    font-size: 3rem;
  }
  .surfectmaintag p {
    font-size: 1.4rem;
  }
  .target1 {
    height: 65px;
  }
  .target2 {
    height: 45px;
  }
  .strait1text h3,
  .strait2text h3 {
    font-size: 1.5rem;
  }
  .strait1text p,
  .strait2text p {
    font-size: 1.3rem;
    width: 300px;
  }
}
@media only screen and (max-width: 600px) {
  .surfecttrait1 {
    left: 15%;
  }
  .surfecttrait2 {
    left: 10%;
    top: 48%;
  }
  .strait1text p,
  .strait2text p {
    width: 200px;
  }
}
@media only screen and (max-width: 500px) {
  .surfectmaintag p {
    width: 95%;
  }
  .surfectmain-wrapper {
    height: 650px;
  }
}

/* @media only screen and (max-width: 830px) {
  .surftecmaintag p {
    width: 75%;
  }
}
@media only screen and (max-width: 750px) {
  .surftecmaintag p {
    width: 85%;
  }
} */
